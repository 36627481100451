// import { ArrowLeft } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import GlobalDynamicHeader from '@/core/components/common/header/GlobalDynamicHeader.vue';
import AddReviewModal from '@/core/components/common/modals/AddReviewModal.vue';
// import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import BalanceView from '@/core/components/ui/BalanceView.vue';
import Button from '@/core/components/ui/Button.vue';
import LabeledThumbnail from '@/core/components/ui/LabeledThumbnail.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import GanttChart from '@/modules/project-schedule/components/GanttChart.vue';
import PhaseList from '@/modules/project-schedule/components/PhaseList.vue';
import TotalCostComponent from '@/modules/project-schedule/components/TotalCostComponent.vue';
import { PROJECTS_ROUTE } from '@/modules/projects/routes';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECT_STAGES_TASKS_STORE } from '@/store/modules/project-quotes/submodules/project-stages-tasks';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
const SCHEDULE_SCREEN = 'schedule';
export default defineComponent({
    name: 'estimate-schedule',
    components: {
        // ArrowLeft,
        GanttChart,
        PhaseList,
        TotalCostComponent,
        AddReviewModal,
        GlobalDynamicHeader,
        Button,
        // ActionItemComponent,
        BalanceView,
        Loader,
        LabeledThumbnail
    },
    mounted() {
        this.initialize();
    },
    data() {
        return {
            colors: ['#4A92E5', '#D84F50', '#EB8C49', '#5DBA59', '#8454DD', '#3E72E1'],
            isOwner: true,
            isScroll: false,
            selectedProject: null,
            selectedEstimate: null,
            originalEstimate: null,
            isLoadingSubmit: false,
            screen: 'schedule',
            ProjectStageTypeEnum,
            openUserRatingModal: false,
            hasUpdatedEstimate: false,
            hasUpdatedEstimateTasks: false,
            SCHEDULE_SCREEN,
            isLoading: false
        };
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(USERS_STORE, ['user']),
        totalCost() {
            if (this.selectedEstimate?.projectStages) {
                return this.selectedEstimate?.projectStages.reduce((sum, projectStage) => sum + projectStage?.stageCost, 0);
            }
            return 0;
        },
        isAccepted() {
            return parseInt(this.selectedEstimate?.projectQuoteStatus.id, 10) >= 2;
        },
        companyName() {
            const cmp = this;
            const creator = cmp.selectedEstimate?.projectQuoteCreator;
            const businessDetails = creator?.userBusinessDetails;
            return (businessDetails?.businessName || `${creator?.firstName} ${creator?.lastName}`);
        },
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(USERS_STORE, ['getUserRatingsPageList', 'createUserRating', 'getUserRatingSummary']),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId']),
        ...mapActions(PROJECT_QUOTES_STORE, ['getProjectQuoteDetails', 'updateProjectQuote', 'getCustomerPay']),
        ...mapActions(PROJECT_STAGES_TASKS_STORE, [
            'checkAllProjectStagesIfCompleted'
        ]),
        getMappedPhases(x, i) {
            // const diff = moment.duration(moment(x.endDate).startOf('day').diff(moment(x.startDate).startOf('day')));
            const modifiedPhase = {
                expand: true,
                color: x.color || this.colors[i % 6],
            };
            return Object.assign(x, modifiedPhase);
        },
        routeToPropertyOverview() {
            this.$router.push({ name: 'overview' });
        },
        onChangeRequest() {
            this.$router.push({ name: 'estimate-form' });
        },
        async initialize() {
            this.isLoading = true;
            const { projectRefId, quoteId } = this.$route.params;
            let qid = quoteId || null;
            await this.getProjectByRefId(projectRefId)
                .then(async (response) => {
                if (response) {
                    this.selectedProject = response;
                    //
                    if (response?.confirmedQuote) {
                        qid = response?.confirmedQuote?.id;
                    }
                    if (response?.projectQuotes?.length) {
                        let estimate = response.projectQuotes.find((x) => x.id === Number(qid));
                        // ensure account
                        if (this.isTradesperson) {
                            estimate = response.projectQuotes.find((x) => x.projectQuoteCreator.id === this.user.id);
                        }
                        if (estimate) {
                            const quotePrice = await this.getProjectQuoteDetailsWithTotalQuotePrice(estimate);
                            estimate.quotePrice = quotePrice;
                            estimate.quoteTotalDuration = estimate.projectStages.filter((item) => item.type === ProjectStageTypeEnum.LABOUR)
                                .map((stage) => {
                                return stage.totalDuration;
                            }).reduce((a, b) => a + b, 0);
                            const mappedEstimate = {
                                ...estimate,
                                projectStages: estimate.projectStages.map(this.getMappedPhases),
                            };
                            this.selectedEstimate = mappedEstimate;
                            this.originalEstimate = mappedEstimate;
                            this.isAllProjectStagesCompleted();
                        }
                    }
                    else {
                        this.routeToPropertyOverview();
                    }
                }
            })
                .catch((error) => {
                this.$notify.error({
                    message: error && error?.message ? error?.message : 'Project not found.'
                });
                this.$router.push({ name: PROJECTS_ROUTE });
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        async isAllProjectStagesCompleted() {
            const { selectedEstimate } = this;
            const hasInProgress = await this.checkAllProjectStagesIfCompleted(selectedEstimate?.projectStages);
            if (!hasInProgress && this.isProjectOwner) {
                const checkPOHasRatedResponse = await this.getUserRatingsPageList({
                    userId: selectedEstimate?.projectQuoteCreator?.id,
                    filter: {
                        refId: this.selectedProject?.id,
                    }
                });
                if (!checkPOHasRatedResponse?.data?.length) {
                    this.openUserRatingModal = true;
                }
            }
        },
        onCloseReviewModal() {
            this.openUserRatingModal = false;
        },
        async onCreateUserRating(payload) {
            await this.createUserRating(payload)
                .then(() => {
                this.$notify.success({
                    message: 'Rating successfully submitted.'
                });
            }).catch(() => {
                this.$notify.error({
                    message: 'Rating submission failed.'
                });
            })
                .finally(() => {
                this.openUserRatingModal = false;
            });
        },
        async getProjectQuoteDetailsWithTotalQuotePrice(projectQuote) {
            let result = {
                total: 0,
                serviceFee: 0,
            };
            const projectId = this.selectedProject?.id;
            const projectQuoteDetails = await this.getProjectQuoteDetails({ projectQuoteId: projectQuote?.id, projectId });
            result = await this.getCustomerPay({ projectId: this.selectedProject?.id, quote: projectQuoteDetails });
            return result;
        },
        backToEstimates() {
            const { projectRefId } = this.$route.params;
            this.$router.push({ name: 'project-estimate', params: { projectRefId } });
        },
        updateIsScroll(value) {
            this.isScroll = value;
        },
        updateEstimate(updatedEstimate) {
            const estimate = JSON.parse(updatedEstimate);
            this.hasUpdatedEstimate = true;
            this.selectedEstimate = estimate;
        },
        updateEstimateTasks(projectStages) {
            this.selectedEstimate.projectStages = projectStages;
            this.hasUpdatedEstimateTasks = true;
        },
        // async submitEstimate() {
        // },
        async submitEstimate() {
            this.isLoadingSubmit = true;
            const { projectStages: originalProjectStages } = JSON.parse(JSON.stringify(this.originalEstimate));
            const selectedEstimateClone = JSON.parse(JSON.stringify(this.selectedEstimate));
            const { projectStages } = selectedEstimateClone;
            // if there are no changes to data just return
            if (!this.hasUpdatedEstimate && !this.hasUpdatedEstimateTasks) {
                this.isLoadingSubmit = false;
                return;
            }
            // const stagesCheck = [] as any;
            // let validationErrors = 0;
            const updatedEstimate = {
                ...this.originalEstimate,
                projectStages: originalProjectStages.map((x) => {
                    const foundStage = projectStages.find((i) => i.id === x.id);
                    // not required!
                    delete x.dateCreated;
                    delete x.dateUpdated;
                    delete x.refId;
                    delete x.description;
                    delete x.stageCostType;
                    delete x.chipMessageStatus;
                    delete x.projectStageAttachments;
                    delete x.projectStageStatus;
                    delete x.expand;
                    delete x.color;
                    delete x.serviceFee;
                    delete x.containerWidth;
                    delete x.stageCostWithFee;
                    const totalDuration = foundStage.totalDuration || foundStage.projectStageTasks.map((i) => {
                        return i.duration;
                    }).reduce((a, b) => a + b, 0);
                    if (foundStage.stageType === ProjectStageTypeEnum.MATERIALS) {
                        return {
                            ...x,
                            startDate: foundStage.startDate,
                            endDate: foundStage.endDate,
                            totalDuration,
                            projectStageTasks: x.projectStageTasks.map((task) => {
                                // not required!
                                delete task.id;
                                delete task.dateCreated;
                                delete task.dateUpdated;
                                delete task.taskType;
                                delete task.done;
                                return {
                                    ...task
                                };
                            })
                        };
                    }
                    // stagesCheck.push({
                    //   id: foundStage.id,
                    //   startDate: foundStage.startDate,
                    //   endDate: foundStage.endDate,
                    //   stageType: foundStage.stageType,
                    //   tasks: []
                    // });
                    const { startDate, endDate, totalDuration: totalDays } = foundStage;
                    const phaseStartDate = moment(startDate);
                    let phaseEndDate = moment(endDate);
                    const diffPhase = phaseEndDate.diff(phaseStartDate, 'days');
                    // correct end date discrepancy
                    if (totalDays !== diffPhase) {
                        phaseEndDate = moment(startDate).add(totalDays, 'days');
                    }
                    return {
                        ...x,
                        startDate: foundStage.startDate,
                        endDate: phaseEndDate.format('YYYY-MM-DD'),
                        totalDuration: parseInt(totalDuration, 10),
                        projectStageTasks: x.projectStageTasks.map((task) => {
                            const foundTask = foundStage.projectStageTasks.find((t) => t.id === task.id);
                            // const stageCheck = stagesCheck.find((z) => z.id === x.id);
                            const { startDate: start, endDate: end, duration } = foundTask;
                            const taskStartDate = moment(start);
                            let taskEndDate = moment(end);
                            const diff = taskEndDate.diff(taskStartDate, 'days');
                            // correct end date discrepancy
                            if (duration !== diff) {
                                taskEndDate = moment(start).add(duration, 'days');
                            }
                            // stageCheck.tasks.push({
                            //   id: foundTask.id,
                            //   startDate: taskStartDate.format('YYYY-MM-DD'),
                            //   endDate: taskEndDate.format('YYYY-MM-DD')
                            // });
                            // not required!
                            delete task.id;
                            delete task.dateCreated;
                            delete task.dateUpdated;
                            delete task.taskType;
                            delete task.done;
                            return {
                                ...task,
                                startDate: taskStartDate.format('YYYY-MM-DD'),
                                endDate: taskEndDate.format('YYYY-MM-DD'),
                                duration: foundTask.duration
                            };
                        })
                    };
                })
            };
            // if (stagesCheck.length > 0) {
            //   for (let i = 0; i < stagesCheck.length; i++) {
            //     const stage = stagesCheck[i];
            //     if (stage.stageType === ProjectStageTypeEnum.LABOUR) {
            //       if (!stage.startDate || !stage.endDate) {
            //         // eslint-disable-next-line no-continue
            //         continue;
            //       }
            //       for (let j = 0; j < stage.tasks.length; j++) {
            //         const task = stage.tasks[j];
            //         if (!task.startDate || !task.endDate) {
            //           // eslint-disable-next-line no-continue
            //           continue;
            //         }
            //         if (!moment(task.startDate).isBetween(stage.startDate, stage.endDate, null, '[]')
            //           || !moment(task.endDate).isBetween(stage.startDate, stage.endDate, null, '[]')) {
            //           validationErrors = +1;
            //         }
            //       }
            //     }
            //   }
            // }
            // // should pop-up an alert modal
            // if (validationErrors) {
            //   // alert('Ensure tasks start and end date is within its phase period');
            //   validationErrors = 0;
            // }
            const { notes, projectStages: stages, projectQuoteAttachments, suggestedStartDate } = updatedEstimate;
            const formData = {
                projectId: this.selectedProject.id,
                notes,
                projectStages: stages,
                projectQuoteAttachments,
                projectQuoteStatusId: this.selectedProject.projectQuotes[0].projectQuoteStatus.id,
                suggestedStartDate
            };
            await this.updateProjectQuote({
                projectId: this.selectedProject.id,
                projectQuoteId: this.selectedEstimate.id,
                formData
            }).then(() => {
                this.hasUpdatedEstimate = false;
                this.hasUpdatedEstimateTasks = false;
                this.isLoadingSubmit = false;
            });
        },
        setScreen(newScreen) {
            this.screen = newScreen;
        }
    }
});
